<template>
    <div class="background-cream">

        <v-container fluid v-if="!$vuetify.breakpoint.smAndUp">
            <v-row>
                <v-col class="pa-0">

                    <router-link to="/">
                        <v-img
                            alt="Marisol Logo"
                            class="shrink logo-header mx-auto"
                            contain
                            src="@/assets/icons/MariSolMalibu_logo-NEGRO.svg"
                            transition="scale-transition"
                        />
                        <v-img
                            alt="Marisol Malibu Logo"
                            contain
                            src="@/assets/icons/MALIBU_logo-NEGRO.svg"
                            class="intro__logo-2 mt-5 mx-auto"
                        ></v-img>
                    </router-link>
                    <!-- btn para el menú mobile -->
                    <div class="text-center mt-6 d-sm-none"  style="filter: invert(1);">
                        <v-btn
                        @click="$emit('open')"
                        :ripple="false"
                        text
                        class="letter-spacing-0 not-text-transform"
                        >
                        <span class="continuos-texts white--text">Menu</span>
                        </v-btn>
                    </div>
                    <!-- /btn para el menú mobile -->
                </v-col>
            </v-row>
        </v-container>

        <v-container class="block-second">
            <v-row>
                <v-col>
                    <div v-html="cookieConsent.text" class="cookieConsentContainer">
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cookieConsent: {
                text: ''
            }
        }
    },
    beforeMount: function () {
        fetch('https://content.marisolmalibu.com/api/singletons/get/CookieConsent')
        .then(data => data.json())
        .then(data => this.cookieConsent = data);
    },
    mounted: function () {
        this.$gtag.pageview({ page_path: '/privacy' ,page_name: 'Cookie Consent'})
    }
}
</script>